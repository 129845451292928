
import Vue from "vue";
import Component from "vue-class-component";

@Component({ name: "StripeCallback" })
export default class extends Vue {
  get success() {
    return !!this.$route.query.success;
  }
}
